import React from "react";
import { useNavigate } from 'react-router-dom';

const Navbar = () => {

    const navigate = useNavigate();
    const handleNavigation = (section) => {
        if (window.location.pathname !== '/') {
            navigate('/');
        }
        setTimeout(() => {
            document.querySelector(section)?.scrollIntoView({ behavior: 'smooth' });
        }, 100);
    };

    return (
        <div>

            <div className="desktopNavbar">
                <div className="desktopNavWrapper container-fluid">
                    <div>
                        <ul>
                            <li>
                                <a className="desktopLogo fontColorGreen" href="/"> &#123; KD &#125;</a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ul className="desktopNavLinks">
                            <li className="desktopNavLink">
                                <a className="navLink fontColorGreen" href='/'>Home</a>
                            </li>
                            <li className="desktopNavLink"> 
                                <a href="/" className="navLink fontColorGreen" onClick={(e) => { e.preventDefault(); handleNavigation('#about'); }}>About</a>
                            </li>
                            <li className="desktopNavLink">
                                <a className="navLink fontColorGreen" href='/projects'>Projects</a>
                            </li>
                            <li className="desktopNavLink">
                                <a href="/" className="navLink fontColorGreen" onClick={(e) => { e.preventDefault(); handleNavigation('#services'); }}>Services</a>
                            </li>
                            <li className="desktopNavLink"> 
                                <a href="/" className="navLink fontColorGreen" onClick={(e) => { e.preventDefault(); handleNavigation('#contact'); }}>Contact</a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <a className="navLink fontColorGreen" href="https://billing.stripe.com/p/login/fZeeXf2xsa1Q3Di8ww" target="_blank" rel="noopener noreferrer nofollow">Login</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            
            <div className="mobileNavbar">
                <nav className="navbar fixed-top  bgColorTan">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="/"> <span className="navbar-brand-brackets fontColorGreen navbar-brand-size">&#123; KD &#125;</span></a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                        <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" data-bs-dismiss="offcanvas">
                            <div className="offcanvas-header">
                                <h5 className="offcanvas-title" id="offcanvasNavbarLabel">&#123; KD &#125;</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div className="offcanvas-body">
                                <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                                <li className="nav-item">
                                    <a className="nav-link" href="/">Home</a>
                                </li>
                                <li className="desktopNavLink"> 
                                    <a href="/" className="nav-link" onClick={(e) => { e.preventDefault(); handleNavigation('#about'); }}>About</a>
                                </li>
                                <li className="nav-item"> 
                                    <a className="nav-link" href='/projects'>Projects</a>
                                </li>
                                <li className="desktopNavLink"> 
                                    <a href="/" className="nav-link" onClick={(e) => { e.preventDefault(); handleNavigation('#services'); }}>Services</a>
                                </li>
                                <li className="desktopNavLink"> 
                                    <a href="/" className="nav-link" onClick={(e) => { e.preventDefault(); handleNavigation('#contact'); }}>Contact</a>
                                </li>


                                <br></br>
                                <li className="nav-item">
                                    <a className="nav-link" href="https://billing.stripe.com/p/login/fZeeXf2xsa1Q3Di8ww" target="_blank" rel="noopener noreferrer">Login</a>
                                </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>   
            </div>
        </div>
    );
}

export default Navbar;