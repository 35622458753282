import React from "react";

const About = () => {
    return (
        <div>

            <div className="aboutWrapper">
                <div className="aboutCol aboutColText bgColorGreen fontColorCream">
                    <h2 className="headingTextDecor">About</h2>
                    <p>I am a freelance web developer with a passion to help 
                        businesses with their online presence. I help businesses 
                        design, build, and maintain their website to ensure the 
                        best quality, user experience, and functionality.</p>
                </div>
                <div className="aboutCol fontColorGreen bgColorTan">
                    <h3 className="aboutQuote">getToKnowMe &#123; <p>capableOf: designing, building, maintaining ;</p> &#125;</h3>
                </div>
                <div className="aboutCol aboutColText bgColorGreen fontColorCream">
                    <h2 className="headingTextDecor">Skills</h2>
                    <p>
                        &#8226; Adaptable to the most popular website builders<br></br>
                        &#8226; Knowledge in HTML, CSS, and Javascript<br></br>
                        &#8226; SEO Optimization <br></br>
                        &#8226; Figma Design Tool<br></br>
                        &#8226; Business Admin
                    </p>
                </div>
            </div>
            
            
        </div>
    );
}
export default About;